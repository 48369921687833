
import {
  computed,
  defineComponent,
  Emitter,
  inject,
  onBeforeMount,
  onMounted,
  ref,
} from "vue";
import router from "@/router";
import { useStore } from "@/store";
import { AppActions, AppMutation } from "@/store/types";
import BussEventType from "@/types/BussEventType";
import { useI18n } from "vue-i18n";
import { MessageBoxActionType, MessageBoxEvent } from "@/types/MessageBox";
import { getCookieValue } from "@/utils";
import CookiesToken from "@/types/CookiesToken";

export default defineComponent({
  name: "select-server-line",
  setup() {
    const emitter = inject("emitter") as Emitter;
    const { t } = useI18n();

    const store = useStore();
    const line1 = ref<boolean>(false);
    const line2 = ref<boolean>(false);
    const line3 = ref<boolean>(false);

    const serverStr = ref<string>("");

    const visitCustumerService = () => {
      window.open(process.env.CUSTOMER_SERVICE_URL, "_blank");
    };

    onBeforeMount(() => {
      window.sessionStorage.clear();
      console.log(getCookieValue(CookiesToken.LINE));
    });

    const clickLine = (line: number) => {
      if (line === 1 && !line1.value) return;
      else if (line === 2 && !line2.value) return;
      else if (line === 3 && !line3.value) return;

      store.commit(AppMutation.APP_SET_LINE, line);
      router.push({ name: "login" });
    };

    const enterLoginPage = (api: string) => {
      store.commit(AppMutation.APP_SET_LINE, -1);
      store.commit(AppMutation.APP_SET_LINE_STR, api);
      router.push({ name: "login" });
    };

    const isValidSevice = (urlStr: string): boolean => {
      const pattern = new RegExp(
        "^((ft|htt)ps?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?" + // port
          "(\\/[-a-z\\d%@_.~+&:]*)*" + // path
          "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      return pattern.test(urlStr);
    };

    const enterLogin = () => {
      if (serverStr.value.length > 0) {
        let url = serverStr.value.replace(/\/$/, "");

        let appendHttp = true;

        if (url.startsWith("https://")) {
          appendHttp = false;
        } else if (url.startsWith("http://")) {
          appendHttp = false;
        }

        if (appendHttp) url = `http://${url}`;

        if (!isValidSevice(url)) {
          emitter.emit(BussEventType.TOAST_MESSAGE, t("lines.invalidip"));
          return;
        }

        store.commit(AppMutation.SHOW_LOADING);

        store
          .dispatch("pingServer", { line: -1, url })
          .then((response) => {
            store.commit(AppMutation.HIDE_LOADING);
            if (response === "test ok") {
              store.commit(AppMutation.APP_SET_LINE, -1);
              store.commit(AppMutation.APP_SET_LINE_STR, url);
              router.push({ name: "login" });
            } else {
              emitter.emit(BussEventType.TOAST_MESSAGE, t("lines.invalidip"));
            }
          })
          .catch(() => {
            store.commit(AppMutation.HIDE_LOADING);
            emitter.emit(BussEventType.TOAST_MESSAGE, t("lines.invalidip"));
          });
      }
    };

    const pingServer = async (line: number) => {
      const params = { line, url: "" };
      store
        .dispatch(AppActions.PING_SERVER, params)
        .then(() => {
          if (line === 1) line1.value = true;
          else if (line === 2) line2.value = true;
          else if (line === 3) line3.value = true;
        })
        .catch(() => {
          if (line === 1) line1.value = false;
          else if (line === 2) line2.value = false;
          else if (line === 3) line3.value = false;
        });
    };

    const onShowLanguageMsbBox = () => {
      const _event: MessageBoxEvent = {
        type: MessageBoxActionType.open,
        name: "lang",
      };

      emitter.emit(BussEventType.DOALOG, _event);
    };

    //#region Computed Cariables
    const lang = computed((): string => store.getters["lang"]);
    const isMobileDisplay = computed((): boolean => store.getters["isMobile"]);
    //#endregion

    onMounted(() => {
      pingServer(1);
      pingServer(2);
      pingServer(3);
    });

    return {
      serverStr,
      line1,
      line2,
      line3,
      isMobileDisplay,
      lang,
      t,
      visitCustumerService,
      enterLoginPage,
      clickLine,
      enterLogin,
      onShowLanguageMsbBox,
    };
  },
});
